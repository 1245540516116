
import { Instagram, MessageCircle } from "lucide-react";
import { motion } from "framer-motion";

const Index = () => {
  const links = [
    {
      title: "Instagram",
      icon: <Instagram size={24} />,
      url: "https://www.instagram.com/pozapo.official",
      color: "bg-gradient-to-r from-purple-500 to-pink-500",
    },
    {
      title: "TikTok",
      icon: <span className="text-xl">TikTok</span>,
      url: "https://www.tiktok.com/@pozapo.official",
      color: "bg-black",
    },
    {
      title: "WhatsApp",
      icon: <MessageCircle size={24} />,
      url: "https://wa.me/972547370129",
      color: "bg-green-500",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1C1C1C] to-[#2D2D2D]">
      <div className="container max-w-2xl mx-auto px-4 py-8">
        <div className="space-y-8">
          {/* Logo and Header */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center space-y-6"
          >
            <img 
              src="/lovable-uploads/b3ffc413-c797-4e58-9365-f705cda4df81.png" 
              alt="Pozapo Logo" 
              className="w-64 mx-auto mb-6"
            />
            <div className="space-y-3">
              <p className="text-xl text-[#C4A86D] font-semibold">
                קיר צילום | קיר כניסה
              </p>
              <p className="text-lg text-gray-300 leading-relaxed">
                קירות צילום יחודיים לאירועים בלתי נשכחים
              </p>
              <p className="text-lg text-gray-300">
                📍 ישראל | זמינים לאירועים פרטיים ועסקיים
              </p>
              <p className="text-lg text-[#C4A86D]">
                שלחו הודעה להזמנות!
              </p>
            </div>
          </motion.div>

          {/* Links */}
          <div className="space-y-4">
            {links.map((link, index) => (
              <motion.a
                key={link.title}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`link-item flex items-center justify-between w-full p-4 rounded-lg text-white ${link.color}`}
              >
                <span className="font-medium">{link.title}</span>
                {link.icon}
              </motion.a>
            ))}
          </div>

          {/* Footer */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="text-center text-sm text-gray-400"
          >
            © {new Date().getFullYear()} Pozapo. All rights reserved.
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Index;
